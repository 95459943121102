.userbescheinigungsliste {
    background-color: #f5f4fd66;
    border: 1px solid grey;
    border-radius: 6px;
}

.userbescheinigungsliste.pending {
    background-color: rgba(255, 255, 22, 0.479);
}

.userbescheinigungsliste.granted {
    background-color: rgba(96, 255, 22, 0.479);
}

.userbescheinigungsliste.rejected {
    background-color: rgba(255, 22, 22, 0.479);
}


.versioninfo {
    opacity: 0.3;
    /* width: 100%; */
    position: absolute;
    bottom: 5px;
    right: 4px;
}