.reportpage {
    /* background-color: #f1f1f173; */
    background-color: rgba(255, 255, 255, 0.3) !important;
    padding: 5px 5px 40px;
    backdrop-filter: blur(10px)  contrast(0.3) brightness(1.8); 
}

.reportaddfilebox {
    backdrop-filter: blur(25px);
}

.file, .folderselect {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 10px;
    padding: 10px;
    /* justify-content: space-around; */
}

.helpdialog {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px) contrast(0.3) brightness(2.4); 
}

.reportaccordion {
    background-color: inherit;
}

.reportaccordion  div {
    background-color: transparent !important;
    backdrop-filter: blur(25px);
}

.tableinherit th, .tableinherit td {
    color:inherit;
}