.file, .folderselect {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    /* justify-content: space-around; */
}


.filecaption {
    flex-grow: 2;
    margin-left: 30px !important;
}

.fileblog {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px) contrast(0.3) brightness(1.8); 
}

.fullfolder {
    background-color:  #00000013 !important;
}

.fullfolder > div {
    background-color:  #00000013;
}

.onefolder {
    display: flex;
    
    flex-direction: row;
    flex-wrap: wrap;
}

.onefile {
    
    border: 1px solid gray;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    background-color:  #ffffff8c;
}


