.nastartmain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 5px;
}

.userinfodialog {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px) contrast(0.3) brightness(2.4); 
}

.uinner {
    background-color: unset !important;
}

.stepperbox {
    display: flex;
    justify-content: space-between;
}