.App {
    /* width: 100vw; */
    /* min-height: 100vh;  */
    height: 100vh;
    background-image: url('rtwbg.jpg');
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    overflow-y: auto;
}

.head {
    height: 10em;
    background-color: rgba(255, 255, 255, 0.3) !important;
    /* backdrop-filter: blur(10px) contrast(0.3) brightness(1.3) */
    backdrop-filter: blur(10px);
     /* contrast(0.3) brightness(1.8);  */
}

.head.mobile {
  zoom: 0.7;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.marqueesmall {
  zoom: 0.5;
  backdrop-filter: blur(10px) contrast(0.2);
}

.marquee {
  backdrop-filter: blur(10px) contrast(0.2);
}

.logo {
    height: 10em;
    margin-left: 5px;
}

.shortmenu {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    top: 8em;
    width: 80%;
    font-family: monospace;
    color:rgb(0, 0, 0);
}

.shortmenu.smmobile {
  width: 100%;
}

.menubutton {
    width: 18% !important;
    margin:2px !important;
}

.topright {
    position: absolute;
    top:0.5em;
    right: 0.5em;
}

.newsblog, .fileblog {
    /* width: auto;
    min-width: 50vw;
    width: 70vw;*/
    /* height: 100vh;  */
    padding: 30px;
    height: 85vh;
    /* overflow:hidden;  */
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    
  
  /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .newsblog::-webkit-scrollbar {
    display: none;
  }

  .fileblog::-webkit-scrollbar {
    display: none;
  }


.firstrow {
    display:flex;
    flex-direction: row;
    /* width: 70%; */
    justify-content: end;
    margin:10px;
}
.searchfield{
    width: 80%;
}

.blogentry {
  /* background-color: unset !important; */
  background-color: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(10px) contrast(0.3) brightness(1.8); 
    /* min-width: 50vw;
    max-width: 70vw; */
    margin-bottom: 0.5em;
    padding: 20px;
    /* min-height: 3em; */
}

.blogentry.bloghidden {
  filter: opacity(0.3);
}

/* .quill > .ql-toolbar:first-child {
    display: none !important;
  } */

  .filelist {
    margin-bottom: 5px;
  }

  .file, .buttongroup {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
  }

  .zeitraum {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
  }

  .textfield {
    margin: 5px !important;
  }

  .textfield50 {
    margin: 5px !important;
    width: 45%;
  }

  .ql-editor {
    min-height: 30vh;
  }

  img {
    max-width: 100%;
  }

  .swal2-container {
    z-index: 5000 !important;
  }

  .tasks {
    display:flex;
     height: auto;
    flex-direction: column;
  }

  .infostripe {
    width: 100%;
    margin: 3px;
    background-color: #ff0000b5;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    /* border: 1px solid; */
    border: unset;
    backdrop-filter: blur(5px);
    border-radius: 12px;
    cursor: pointer;

  }

  .infostripe.user {
    background-color: rgba(0, 47, 255, 0.322);
  }

  .maildialogbutton {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }

  .aelrdmail {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px)  contrast(0.3) brightness(2.4); 
  }

