.recbuttons {
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
}

.sendbutton {
    float: right;
    margin: 10px;
}

.user, .cme {
    background-color: #f5f4fd66;
    border: 1px solid grey;
    border-radius: 4px;
}

.pending.user {
    background-color: #ffdae066;
}

.cme.pending {
    background-color: #f6fca766;
}

.ulistcontainer {
    padding: 5px;
}

.messagecontainer {
    padding: 45px;
    background-color: #f5f4fd66;
}

.cmedialogding {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    padding: 10px;
}

.managebox {
        /* background-color: #f1f1f173; */
        background-color: rgba(255, 255, 255, 0.3) !important;
        padding: 5px 5px 40px;
        backdrop-filter: blur(10px)  contrast(0.3) brightness(1.8);
}

.react-transform-wrapper {
    height: inherit !important;
}